// import React from "react";
import React, {Component} from 'react';
import classes from "./Shared.module.scss";
import styles from "../Header/Header.module.scss";
import looks from "../Footer/Footer.module.scss";

class Phone extends Component {
  render() {
    let styleParent = classes;
    if (this.props.styleParent === 'styles') {
      styleParent = styles;
    } else if (this.props.styleParent === 'looks') {
      styleParent = looks;
    }
    return (
      <a className={[styleParent.callLink, styleParent[this.props.where]].join(' ')} href="tel: +12512669921">
        {this.props.addText ? [this.props.addText] : null} 
        {this.props.br ? <br className={styleParent[this.props.br]} /> : null}
        {this.props.callText ? [this.props.callText] : '251·266·9921'}
      </a>
    )
  }
}
export default Phone;