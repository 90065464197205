import React from "react";
// import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
import Social from "../Social/Social";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
// import Drawer from "../Header/Drawer";
// import footerImg from "../../assets/images/blaze26.jpg";

const footer = () => {
  return (
    <footer id="contact" className={[classes.Footer, classes.sideTitle].join(' ')} data-uk-parallax="bgy: -100; media:767;">
      {/* <div className={classes.quote}>
      <p>"I’ve been in the dog care/training industry for over 15 years, and have never used a groomer for my personal pets…that is, until I met Blaze! She is absolutely the best at what she does, and I trust her completely with my dogs and those of my clients. I’ve also never met a groomer that can groom cats the way she does. My babies are always so happy after their groom with her! The joy she brings to the job is unmatched, and I learn something new from her every time we chat, whether it’s about technique, coat care, or product, she’s always furthering her skill set to be the best in her industry. I truly admire and appreciate that!"</p>
      </div> */}

      <div className={classes.FooterBody}>
        <p className={[classes.secTitle].join(' ')}>Contact Us</p>

        <div className={classes.inside}>
          <div className={classes.top}>
            <div className={classes.contact}>
              <p><span>Call Us:</span><br/><Phone where="footer" styleParent="looks" /></p>
              <p><span>Email Us:</span><br/><Email where="footer" /></p>
            </div>

            <div className={classes.social}>
              <p className={classes.bmargin0}><span>Follow Us:</span></p>
              <Social where="round" />
            </div>
          </div>
      
          <div className={classes.btm}>
            <p className={[classes.copy, classes.left].join(' ')}>© {(new Date().getFullYear())} Grooms by Blaze. All rights reserved.
              Design by <a href="https://hsdesignhouse.com" target="_blank" rel="noreferrer">High Seas Design House.</a>
            </p>
          </div>
        </div>
      </div>

      <div className={[classes.FooterWave, classes.Lwave].join(' ')}>
        <svg id="visual" viewBox="0 0 2000 350" xmlns="http://www.w3.org/2000/svg" version="1.1" className={classes.ftWave}>
          <path d="M0 103L111.2 93.8C222.3 84.7 444.7 66.3 666.8 84.2C889 102 1111 156 1333.2 222.5C1555.3 289 1777.7 368 1888.8 407.5L2000 447L2000 501L1888.8 501C1777.7 501 1555.3 501 1333.2 501C1111 501 889 501 666.8 501C444.7 501 222.3 501 111.2 501L0 501Z"></path>
          {/* <path d="M0 82L111.2 74.7C222.3 67.3 444.7 52.7 666.8 67C889 81.3 1111 124.7 1333.2 178C1555.3 231.3 1777.7 294.7 1888.8 326.3L2000 358L2000 401L1888.8 401C1777.7 401 1555.3 401 1333.2 401C1111 401 889 401 666.8 401C444.7 401 222.3 401 111.2 401L0 401Z"></path> */}
        </svg>
      </div>
    </footer>
  );
}
export default footer;