import React, {Component} from 'react';
import { Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classes from './App.module.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from './hoc/Home/Home';
// import Fonts from './hoc/Fonts/Fonts';
// import Services from './hoc/Services/Services';
import Fallback from './hoc/Fallback/Fallback';

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact={true} path="/" component={Home} />
        {/* <Route path="/fonts" component={Fonts} /> */}
        {/* <Route path="/services" component={Services} /> */}
        <Route component={Fallback} />
      </Switch>
    );
    const pathName = window.location.pathname.replace('-','');
    const wrapClass = pathName.replace('/', '');

    return (
      <div className={[classes.blazeWrap, classes[wrapClass]].join(' ')}>
        <Header />

        <main className={classes.Content}>
          {routes}
        </main>

        <Footer />
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          // style={{fill:'rgb(255,255,255)', backgroundColor:'#000', padding: '0px 5px', }}
          EasingType='linear'
        />
      </div>
    );
  }
}
export default App;
