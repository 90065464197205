import React from "react";
const classes = require('./Modal.module.scss');

const modal = (props) => {
  return (
    <div>
      {props.btnText ? <button type="button" data-uk-toggle={"target: #" + [props.id]} className={[classes[props.btnClass1], classes[props.btnClass2]].join(' ')}>{props.btnText}</button> : null }
      
      <div id={props.id} className={[classes.modalFull, classes[props.modalClass], "uk-flex-top"].join(' ')} data-uk-modal>
        <div className={[classes.modalDialog, classes[props.class2], "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"].join(' ')}>
          <button className={[classes.closeIt, "uk-modal-close-default"].join(' ')} type="button" data-uk-close></button>
          <div className={classes[props.wrap]}>
            {props.modalTitle ? <p className={[classes.h3, classes[props.tborder]].join(' ')}>{props.modalTitle}</p> : null }
            {props.tborder === "border" ? <div className={classes.tborder}><hr/><hr/></div> : null }
            <div className={classes.modalContent}>{props.modalContent}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
modal.defaultProps = {
  wrap: "inside",
  tborder: "border"
};
export default modal;