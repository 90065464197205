import React from "react";
const classes = require('./Social.module.scss');

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.facebook.com/GroomsbyBlaze" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Grooms by Blaze Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

      <li><a href="https://www.instagram.com/groomsbyblaze/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Grooms by Blaze Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>

      <li><a href="https://twitter.com/Groomsbyblaze?t=GauvUF4m6bxmLEKuVqmgvw&s=09" target="_blank" rel="noopener noreferrer">
        <span aria-label="Grooms by Blaze Twitter Link" uk-icon="icon: twitter"></span>
      </a></li>
    </ul>
  );
};

export default social;
