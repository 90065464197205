import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
import NavItems from "./NavItems";
import Drawer from "./Drawer";
import Social from "../Social/Social";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import logo from "../../assets/images/groomsLogo.png";
// const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);

class Header extends Component {
  render() {
    return (
      <header className={[classes.Header, classes[this.props.HeaderType]].join(' ')}>
        <div className={[classes.HeaderWave, classes.hTwave].join(' ')}>
          <svg id="visual" viewBox="0 0 2000 300" xmlns="http://www.w3.org/2000/svg" version="1.1" className={classes.hdWave}>
            <path d="M0 82L111.2 74.7C222.3 67.3 444.7 52.7 666.8 67C889 81.3 1111 124.7 1333.2 178C1555.3 231.3 1777.7 294.7 1888.8 326.3L2000 358L2000 401L1888.8 401C1777.7 401 1555.3 401 1333.2 401C1111 401 889 401 666.8 401C444.7 401 222.3 401 111.2 401L0 401Z"></path>
          </svg>
        </div>

        <div className={classes.infoRow}>
          <Phone where="navbar" styleParent="styles" />
          <Email where="navbar" styleParent="styles" />
          <Social where="header" />
        </div>

        <div className={[classes.insideXl, classes.hdWrap].join(' ')}>
          <nav className={classes.navMm}>
            <div className={[classes.hdCol, classes.colLogo].join(' ')}>
              <Link to="/" className={classes.logoLink}>
                <img src={logo} alt="Grooms by Blaze logo" className={[classes.logoImg, classes.logoImgFw].join(' ')} />
              </Link>
            </div>
            
            <div className={classes.clearfix} />
            <div className={[classes.hdCol, classes.colNav].join(' ')}>
              <NavItems addClass="navArea" scrollType="data-uk-scroll" />
            </div>

            <div className={classes.mobNav}><Drawer /></div>
          </nav>        
        </div>
      </header>
    );
  }
}

export default Header;


