import React from 'react';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import classes from "./Header.module.scss";
import styles from "../Footer/Footer.module.scss";
import NavItems from "./NavItems.js";
import Social from "../Social/Social";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import logo from "../../assets/images/groomsLogo.png";

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <img src={logo} alt="Logo" className={[classes.logoImg, classes.logoImgFw].join(' ')} />
      <hr/>
      <NavItems navClass="SideNav" />
      
      <div className={classes.contactSec}>
        <hr/>
        <p><Phone where="drawer" styleParent="styles" /></p>
        <p><Email where="drawer" styleParent="styles" /></p>
        <Social where="drawer" />

      </div>
    </div>
  );

  return (
    <div className={[classes.NavDrawer, styles.ftDrawer].join(' ')}>
      <button className={classes.XhsLink} onClick={toggleDrawer('left', true)}>
        <span className={classes.menuIcon} data-uk-icon="icon:menu;"></span>
      </button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;