import React from "react";
// import { Link } from "react-router-dom";
const classes = require('./Header.module.scss');

const navItems = (props) => {
  return (    
    <ul className={[classes[props.navClass], classes[props.addClass]].join(' ')}>
      {/* <li className={classes.navItem}><a href="#about" data-uk-scroll>About</a></li> */}
      <li className={classes.navItem}>{props.scrollType ? <a href="#about" data-uk-scroll>About</a> : <a href="#about">About</a>}</li> 
      <li className={classes.navItem}>{props.scrollType ? <a href="#services" data-uk-scroll>Services</a> : <a href="#services">Services</a>}</li> 
      <li className={[classes.navItem, classes.drawer].join(' ')}>{props.scrollType ? <a href="#reviews" data-uk-scroll>Reviews</a> : <a href="#reviews">Reviews</a>}</li> 
      <li className={classes.navItem}>{props.scrollType ? <a href="#contact" data-uk-scroll>Contact Us</a> : <a href="#contact">Contact Us</a>}</li> 
      {/* <li className={classes.navItem}><a href="https://booking.moego.pet/form?formId=3b60e9d18cbf535db130def39c2152e5" target="_blank" rel="noreferrer">Waitlist</a></li> */}
      <li className={classes.navItem}><a href="https://booking.moego.pet/ol/landing?name=GroomsbyBlaze" target="_blank" rel="noreferrer">Book Now</a></li>
    </ul>
  );
};
// navItems.defaultProps = {
//   scrollType: "false"
// };

export default navItems;