import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import classes from "./Home.module.scss";
import Modal from "../../components/Modal/Modal";
// import introImg from "../../assets/images/introGroom.jpg";
import introImg2 from "../../assets/images/beachBlaze1.jpg";
import supplies1 from "../../assets/images/supplies1.jpg";
import services1 from "../../assets/images/servicesGroom.jpg";
import services3 from "../../assets/images/eventPup.jpg";
import servicesMob1 from "../../assets/images/blazeVanMobile.jpg"; //
import servicesMob2 from "../../assets/images/servicesGroomMob.jpg"; //
import trim from "../../assets/images/trim.jpg";
import abtBlaze from "../../assets/images/abtBlaze.jpg";
import abtLiz from "../../assets/images/abtLiz.jpg";
import abtBlazeModal from "../../assets/images/abtBlazeMob.jpg";
import abtLizModal from "../../assets/images/abtLizMob.jpg";

const introReview = (
  <li>
    <div className={classes.inLi}>
      <p className={classes.review}>
        "Blaze has become a part of our forever family! I have used her for
        several years because I saw the immediate love she has for animals. She
        has a heart of gold, and there is no one besides my immediate family I
        would trust more with my baby! She is also an incredible artist! I
        highly recommend Blaze, especially if your pet suffers from anxiety like
        mine."
      </p>
      <p className={classes.auth}>- Gina P.</p>
    </div>
  </li>
);

const bhs = (
  <div className={classes.inLi}>
    <p className={classes.review}>
      "Blaze has been volunteering her time for the Baldwin Humane Society for
      years now, and we just don’t know what we’d do without her! She donates
      her time to help our animals in major need of grooming. She has helped our
      most abused cases, and treats them with the upmost care and kindness --
      all while making them look fabulous. We appreciate her so much, and are
      amazed with the work she does."
    </p>
    <p className={classes.auth}>- Baldwin Humane Society</p>
  </div>
);
const academy = (
  <div className={classes.inLi}>
    <p className={classes.review}>
      "I've been in the dog care/training industry for over 15 years, and have
      never used a groomer for my personal pets…that is, until I met Blaze! She
      is absolutely the best at what she does, and I trust her completely with
      my dogs and those of my clients. I've also never met a groomer that can
      groom cats the way she does. My babies are always so happy after their
      groom with her! The joy she brings to the job is unmatched, and I learn
      something new from her every time we chat, whether it's about technique,
      coat care, or product, she's always furthering her skill set to be the
      best in her industry. I truly admire and appreciate that!"
    </p>
    <p className={classes.auth}>- Ashley H.</p>
  </div>
);
const ellie = (
  <>
    <p className={classes.review}>
      "Our sweet Ellie turned 12 weeks old today, and had her first grooming
      with Blaze. Ellie has some anxiety. Blaze was so patient, calm, and kind
      with her. We can't imagine anyone else taking care of Ellie like Blaze
      does. She truly understands dogs, and what they need. Ellie can't wait to
      see Blaze next month."
    </p>
    <p className={classes.auth}>- Teresa C.</p>
  </>
);

const mandog = (
  <li className={classes.mid}>
    <div className={classes.inLi}>
      <p className={classes.review}>
        "Blaze has been grooming our little poodle (also known as mandog) for
        quite some time now. Not only is she creative, precise, artistic, and up
        for a challenge, she also takes great care with Reese! He broke both of
        his front legs when he was a puppy. He doesn't like anyone touching or
        manipulating them. Blaze bought a hammock so that he can hang out and be
        relaxed during his grooming! It's not every day that you can meet a
        trustworthy groomer that not only cares deeply about your pets, but
        comes to your home while you are at work, does an excellent job and then
        locks everything back up before she leaves. It is so wonderful to watch
        Reese run into her arms when she comes in to gather him
        <span className={classes.hideModal}>
          {" "}
          ..."{" "}
          <button
            type="button"
            data-uk-toggle="target: #mdModal"
            className={[classes.modalLink, classes.mdModal].join(" ")}
          >
            Read More
          </button>
        </span>
        <span className={classes.hideFull}>
          , and take him to her beautiful grooming van! I can't say enough
          wonderful things about Blaze! She is the best and the only groomer for
          us!!"
        </span>
      </p>
      <p className={classes.auth}>- Peggy C.</p>
    </div>
  </li>
);
const godsend = (
  <div className={classes.inLi}>
    <p className={classes.review}>
      "Blaze has been a godsend of a groomer. Her patience, communication, and
      general love for what she does is so reassuring for me as a client. I have
      had a PACK of animals (6 dogs, 2 cats), all with their own issues, but she
      helped to make them happy and comfortable for grooming. She has taken care
      of my special needs dog, who has seizures from overstimulation, by making
      it as calm of an environment as possible. She has groomed my ornery cat,
      who used to have to be SEDATED for anything related to grooming, but now
      let's Blaze bathe and shave her with minimal fussy meows. She has taken
      care of my senior dogs, and kept them comfortable, but cute{" "}
      <span className={classes.hideModal}>
        {" "}
        ..."{" "}
        <button
          type="button"
          data-uk-toggle="target: #gsModal"
          className={[classes.modalLink, classes.gsModal].join(" ")}
        >
          Read More
        </button>
      </span>
      <span className={classes.hideFull}>
        . Blaze has also taken care of my pups who were able to be candidates
        for creative grooming, and knocked it out of the park! I could go on and
        on how wonderful she is, but as a groomer you will be hard pressed to
        find anyone as informative, educated, creative, and loving as Blaze. And
        your puppy or kitten will absolutely fall in love with her, and look
        forward to their grooms."
      </span>
    </p>
    <p className={classes.auth}>- Brooke L.</p>
  </div>
);

const bioBlaze = (
  <>
    <p className={classes.name}>Blaze</p>
    <p>
      Blaze is a dedicated pet stylist and{" "}
      award winning competition groomer, who has been transforming
      furry friends into works of art since 2013. With a passion
      for vivid colors and an artistic touch, Blaze specializes in creative
      grooming and Asian fusion styles that bring out the unique personalities
      of your beloved pets. In her free time, she donates her grooming services
      to local shelters, striving to make a positive impact on as many
      four-legged lives as possible.
    </p>
    <div className={classes.more}>
      {" "}
      <button
        type="button"
        data-uk-toggle="target: #blazeModal"
        className={[classes.modalLink, classes.mdModal].join(" ")}
      >
        Read More
      </button>
    </div>
    <p className={classes.mobHide}>
      Blaze is a proud mom of 2 standard poodles, and a special foster fail
      kitty (shown in photo). Alongside her role as a pet stylist, she has also
      fostered over 200 kittens, providing them with the love and care they
      deserve. Her furry family members not only bring her joy, but also inspire
      and inform her approach to grooming.
    </p>
    <p className={classes.mobHide}>
      With over a decade of experience in the industry, and a true desire to
      spoil every animal she meets, Blaze provides a grooming experience full of
      love, comfort and pampering!
    </p>
  </>
);
const bioLiz = (
  <>
    <p className={classes.name}>Elizabeth</p>
    <p>
      Elizabeth began her career as a devoted pet stylist in 2011. Specializing
      in the art of pet trims, she has dedicated her career to enhancing the
      natural beauty of our furry friends, while ensuring they feel comfortable
      and loved throughout the grooming process.
    </p>
    <div className={classes.more}>
      {" "}
      <button
        type="button"
        data-uk-toggle="target: #lizModal"
        className={[classes.modalLink, classes.mdModal].join(" ")}
      >
        Read More
      </button>
    </div>
    <p className={classes.mobHide}>
      With 5 years of experience as a vet tech, Elizabeth brings a unique
      perspective to pet grooming, prioritizing the well-being and health of
      every precious companion. Her favorites include Yorkies, Toy Poodles, and
      Shih Tzus, as their distinct personalities and coats offer endless
      opportunities for creative trims.
    </p>
    <p className={classes.mobHide}>
      Elizabeth is a true animal lover at heart...and at home, with a furry
      family made up of 4 dogs and 2 cats, and an extensive history of fostering
      special needs animals. While she may smile or say hello to people as she
      passes, she cannot resist a furry encounter, and will always stop to pet
      their dogs.
    </p>
  </>
);

const slider = (
  <div
    className={[classes.itSlideWrap].join(" ")}
    tabIndex="-1"
    data-uk-slider="autoplay:true;"
  >
    <ul className={[classes.twistSlide, "uk-slider-items"].join(" ")}>
      <li>
        <img
          src={introImg2}
          className={classes.hs1Img}
          alt="Pet grooming van in Brevard County, Florida"
        />
      </li>
    </ul>

    <button
      className={[
        classes.slideArrow,
        classes.arrowLeft,
        "uk-position-center-left",
      ].join(" ")}
      data-uk-slidenav-previous
      data-uk-slider-item="previous"
    ></button>
    <button
      className={[
        classes.slideArrow,
        classes.arrowRight,
        "uk-position-center-right",
      ].join(" ")}
      data-uk-slidenav-next
      data-uk-slider-item="next"
    ></button>
  </div>
);

const modalBlaze = (
  <>
    {bioBlaze}
    <img src={abtBlazeModal} alt="Grooms by Blaze owner" />
  </>
)
const modalLiz = (
  <>
    {bioLiz}
    <img src={abtLizModal} alt="Grooms by Blaze groomer, Elizabeth" />
  </>
)


const home = () => {
  return (
    <div className={classes.HomePage}>
      <Helmet>
        <title>
          Professional Mobile Pet Grooming in Brevard County, Florida | Grooms
          by Blaze
        </title>
        <meta
          name="description"
          content="Grooms by Blaze offers professional, high-quality mobile pet grooming to residents of Brevard County, Florida."
        />
      </Helmet>

      <div className={classes.nover}>
        <div className={classes.v1outer}>
        <div className={[classes.homeSec1, classes.v1].join(" ")}>
          <div className={classes.medDef}>
            <div className={classes.imageTwist}>{slider}</div>

            <h1 className={classes.mobile}>
              Professional, Certified
              <br /> Mobile Pet Grooming
            </h1>

            <div className={classes.text}>
              <h1 className={classes.fw}>
                Professional, Certified
                <br /> Mobile Pet Grooming
              </h1>
              <div className={classes.bod}>
                <h2>
                  Grooms by Blaze delivers high-quality, reliable mobile pet
                  grooming services right to your door in{" "}
                  <b>
                    Cocoa Beach, Merritt Island, Cape Canaveral, Viera,
                    Rockledge, &amp; North Melbourne, FL.{" "}
                  </b>
                </h2>
                <p>
                  Contact us today to schedule an appointment that you and your
                  furry best friend will both love!
                </p>
                <div className={classes.cta}>
                  <Phone where="blazeLinkRev" />
                  <a
                    className={classes.blazeLink}
                    href="https://booking.moego.pet/ol/landing?name=GroomsbyBlaze"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>

            <div className={classes.imageTwist2}>
              <div className={classes.quote}>
                {introReview}
              </div>
            </div>

            <div className={classes.imageTwistMob}>{slider}</div>
          </div>
          </div>
        </div>

        <div
          id="services"
          className={[classes.homeSecServe, classes.v1].join(" ")}
        >
          <div className={classes.sl1}>
            <h3>Our Services</h3>
            <ul
              className={[classes.tabNav, classes.centerNav].join(" ")}
              data-uk-tab="connect: #serviceTabs; animation: uk-animation-fade"
            >
              <li>
                <button>Standard Grooming</button>
              </li>
              <li>
                <button>Special Events</button>
              </li>
              <li>
                <button>Corporate Services</button>
              </li>
            </ul>
          </div>

          <div className={classes.medDef}>
            <div className={[classes.fullRow, classes.rel].join(" ")}>
              <ul
                id="serviceTabs"
                className={[classes.stabContent, "uk-switcher"].join(" ")}
              >
                <li className={[classes.tcBlock, classes.groom].join(" ")}>
                  <div className={classes.tabText}>
                    <div className={classes.topText}>
                      <p className={classes.h3}>
                        Our standard grooming services are
                        <br /> for dogs and cats{" "}
                        <span className={classes.highlight}>
                          30lbs and under.
                        </span>
                      </p>
                    </div>

                    <div className={[classes.serveHlf, classes.dogs].join(" ")}>
                      <p className={classes.h4}>Dogs</p>
                      <p>
                        1-15<span className={classes.low2}>lbs</span>: $150{" "}
                        <span className={classes.low}>&amp; up</span>
                      </p>
                      <p>
                        16-30<span className={classes.low2}>lbs</span>: $190{" "}
                        <span className={classes.low}>&amp; up</span>
                      </p>
                    </div>

                    <div className={[classes.serveHlf, classes.cats].join(" ")}>
                      <p className={classes.h4}>Cats</p>
                      <p>
                        Bath{" "}
                        <span className={classes.lower}>(short-haired):</span>{" "}
                        $150 <span className={classes.low}>&amp; up</span>
                      </p>
                      <p>
                        Bath{" "}
                        <span className={classes.lower}>(long-haired):</span>{" "}
                        $180 <span className={classes.low}>&amp; up</span>
                      </p>
                      <p>
                        Cat grooming: <span className={classes.Xlower}>$</span>
                        230 <span className={classes.low}>&amp; up</span>
                      </p>

                      <div className={classes.clearfixBtn} />
                      <a
                        className={classes.blazeLink}
                        href="https://booking.moego.pet/ol/landing?name=GroomsbyBlaze"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Book Now
                      </a>
                    </div>
                  </div>
                  <div className={classes.imageTwist}>
                    <img
                      src={trim}
                      className={classes.hs1Img}
                      alt="Mobile pet salon, Grooms by Blaze"
                    />
                  </div>
                </li>

                <li className={[classes.tcBlock, classes.event].join(" ")}>
                  <div className={classes.tabText}>
                    <div className={classes.topText}>
                      <p className={classes.h3}>
                        We offer our special event grooming
                        <br /> for weddings and photoshoots to pets
                        <br />{" "}
                        <span className={classes.highlight}>
                          50lbs and under.
                        </span>
                      </p>
                    </div>
                    <p className={classes.trim}>
                      This first-class spa day leaves your furry friend buffed,
                      fluffed, and camera ready. Just don't blame us when they
                      steal the show!
                    </p>
                    <p className={classes.highlight}>
                      $200 <span className={classes.low}>per hour</span>
                    </p>
                  </div>
                  <div className={classes.imageTwist}>
                    <img
                      src={services3}
                      className={classes.hs1Img}
                      alt="Freshly groomed dog dressed up for special event"
                    />
                  </div>
                </li>

                <li className={[classes.tcBlock, classes.corp].join(" ")}>
                  <div className={classes.tabText}>
                    <div className={classes.topText}>
                      <p className={classes.h3}>
                        It's the new-and-improved <br />
                        "bring your dog to work day."{" "}
                      </p>
                    </div>
                    <p className={classes.trim}>
                      Your team deserves a treat! Businesses can book us for the
                      day, and offer pet grooming for their employees.
                    </p>
                    <p className={classes.highlight}>
                      Minimum of $1000 / 8 hours
                    </p>
                  </div>
                  <div className={classes.imageTwist}>
                    <img
                      src={services1}
                      className={classes.hs1Img}
                      alt="Dog getting groomed inside Grooms by Blaze van"
                    />
                  </div>
                </li>
              </ul>

              <div className={classes.clearfix} />
              <div className={classes.imageTwist2}>
                <img
                  src={supplies1}
                  className={classes.rightImg}
                  alt="Pet grooming supplies"
                />

                <div className={classes.it2Box}>
                  <ul className={classes.serviceDets}>
                    <li>Ears cleaned </li>
                    <li>Sugar paw scrub </li>
                    <li>Facial </li>
                    <li>Premium shampoo</li>
                    <li>Premium conditioner</li>
                    <li>Nails filed </li>
                    <li>Haircut </li>
                    <li>Finishing coat spray</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={[classes.quoteLax].join(" ")}
          tabIndex="-1"
          data-uk-slider="autoplay:true;autoplay-interval:7000;"
          data-uk-parallax="bgy: 250; media:769;"
        >
          <div className={[classes.medDef, classes.relative].join(" ")}>
            <div
              className={[
                classes.slideContainer,
                "uk-slider-container uk-light",
              ].join(" ")}
            >
              <ul className={[classes.slItems, "uk-slider-items"].join(" ")}>
                <li>{bhs}</li>
                <li className={classes.long}>{academy}</li>
                <li className={classes.short}>
                  <div className={classes.inLi}>{ellie}</div>
                </li>
              </ul>
            </div>
          </div>

          <button
            className={[
              classes.slideArrow,
              classes.arrowLeft,
              "uk-position-center-left",
            ].join(" ")}
            data-uk-slidenav-previous
            uk-slider-item="previous"
          ></button>
          <button
            className={[
              classes.slideArrow,
              classes.arrowRight,
              "uk-position-center-right",
            ].join(" ")}
            data-uk-slidenav-next
            uk-slider-item="next"
          ></button>
        </div>

        <div className={[classes.fullImageRow].join(" ")}>
          <div className={classes.irWrap}>
            <img
              src={servicesMob1}
              className={[classes.imageMob, classes.img2, classes.ogShow].join(
                " "
              )}
              alt="Certified groomer, Blaze, with mobile pet salon van"
            />
            <img
              src={servicesMob2}
              className={[classes.imageMob, classes.img1, classes.ogShow].join(
                " "
              )}
              alt="Dog getting haircut in mobile pet grooming van"
            />
          </div>
        </div>

        <div id="about" className={[classes.about, classes.v1].join(" ")}>
          <div className={classes.medDef}>
            <ul data-uk-accordion="collapsible: false">
              <li className={[classes.abItem, classes.i1].join(" ")}>
                <button
                  className={[classes.accordTitle, "uk-accordion-title"].join(
                    " "
                  )}
                  href="#"
                >
                  <p className={classes.h3}>
                    Quality &amp; <br />
                    Convenience
                  </p>
                </button>
                <div
                  className={[
                    classes.accordContent,
                    "uk-accordion-content",
                  ].join(" ")}
                >
                  <p>
                    We come to you! Our mobile grooming van is equipped with
                    everything we need to pamper your pet -- including premium
                    products, and enough interior space to keep them comfortable
                    and calm throughout the process. We clean and sanitize
                    between every appointment, so you can feel at ease knowing
                    that we provide a healthy environment for your pet.
                  </p>
                </div>
              </li>

              <li className={[classes.abItem, classes.i2].join(" ")}>
                <button
                  className={[classes.accordTitle, "uk-accordion-title"].join(
                    " "
                  )}
                  href="#"
                >
                  <p className={classes.h3}>
                    Experience &amp; <br />
                    Professionalism
                  </p>
                </button>
                <div
                  className={[
                    classes.accordContent,
                    "uk-accordion-content",
                  ].join(" ")}
                >
                  <p>
                    We love what we do, and we take it very seriously. We are
                    dedicated to earning the trust of our customers, and
                    maintaining the reliability, high-quality service and
                    breed-specific attention to detail that they have come to
                    expect from us. We are constantly working to expand our
                    knowledge and qualifications, and stay up-to-date on the
                    latest standards, practices, and products within the
                    industry.
                  </p>
                </div>
              </li>

              <li className={[classes.abItem, classes.i3].join(" ")}>
                <button
                  className={[classes.accordTitle, "uk-accordion-title"].join(
                    " "
                  )}
                  href="#"
                >
                  <p className={classes.h3}>
                    Passion <br />
                    for Pets
                  </p>
                </button>
                <div
                  className={[
                    classes.accordContent,
                    "uk-accordion-content",
                  ].join(" ")}
                >
                  <p>
                    We love your pets almost as much as you do, and we want them
                    to enjoy their appointments with us. We are truly devoted to
                    creating a stress-free grooming experience for both you and
                    your pet, all from the comfort of your own driveway. Our
                    sessions do not include any crates or cages, but they do
                    guarantee plenty of love, nose boops, and butt scratches.
                  </p>
                </div>
              </li>
            </ul>

            <div className={classes.absBox}>
              <p className={classes.h3}>Certifications:</p>
              <p className={classes.cert2}>
                AKC S.A.F.E. &middot; Skin and Coat &middot; <br /> Salon
                Sanitation &middot; Pet First Aid &amp; CPR &middot;{" "}
                <br className={classes.mdShow} />
                Certified Creative Grooming Expert
              </p>
            </div>
          </div>
        </div>
        <div className={[classes.teamOuter, classes.insideXl].join(" ")}>
          <div className={[classes.team, classes.v1].join(" ")}>
            <h3>Meet Our Groomers</h3>

            <div className={[classes.teamWrap, classes.medDef].join(" ")}>
              <div className={[classes.item, classes.blaze].join(" ")}>
                <div className={[classes.image].join(" ")}>
                  <p className={classes.name}>Blaze</p>
                  <img
                    src={abtBlaze}
                    className={classes.hs1Img}
                    alt="Grooms by Blaze"
                  />
                </div>

                <div className={[classes.text].join(" ")}>{bioBlaze}</div>
              </div>

              <div className={[classes.item, classes.liz].join(" ")}>
                <div className={[classes.text].join(" ")}>{bioLiz}</div>
                <div className={[classes.image].join(" ")}>
                  <p className={classes.name}>Elizabeth</p>
                  <img
                    src={abtLiz}
                    className={classes.hs1Img}
                    alt="Grooms by Liz"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="reviews"
          className={[classes.quoteLaxMobile].join(" ")}
          tabIndex="-1"
          data-uk-slider="autoplay:false;"
        >
          <div className={[classes.insideNrw, classes.relative].join(" ")}>
            <div
              className={[
                classes.slideContainer,
                "uk-slider-container uk-light",
              ].join(" ")}
            >
              <ul className={[classes.slItems, "uk-slider-items"].join(" ")}>
                <li className={classes.bhs}>{academy}</li>
                <li className={classes.bhs}>{bhs}</li>
                <li className={classes.longest}>{godsend}</li>
                {mandog}
              </ul>
            </div>
          </div>

          <button
            className={[
              classes.slideArrow,
              classes.arrowLeft,
              "uk-position-center-left",
            ].join(" ")}
            data-uk-slidenav-previous
            uk-slider-item="previous"
          ></button>
          <button
            className={[
              classes.slideArrow,
              classes.arrowRight,
              "uk-position-center-right",
            ].join(" ")}
            data-uk-slidenav-next
            uk-slider-item="next"
          ></button>
        </div>

        <Modal
          id="gsModal"
          modalClass="gsModal"
          tborder="none"
          modalContent={godsend}
        />
        <Modal
          id="mdModal"
          modalClass="mdModal"
          tborder="none"
          modalContent={mandog}
        />
        <Modal
          id="blazeModal"
          modalClass="bioModal"
          class2="bio"
          tborder="none"
          modalContent={modalBlaze}
        />
        <Modal
          id="lizModal"
          modalClass="bioModal"
          class2="bio"
          tborder="none"
          modalContent={modalLiz}
        />
      </div>
    </div>
  );
};
export default home;
