import React, {Component} from 'react';
import classes from "./Shared.module.scss";
import styles from "../Header/Header.module.scss";
import looks from "../Footer/Footer.module.scss";

class Email extends Component {
  render() {
    let styleParent = classes;
    if (this.props.styleParent === 'styles') {
      styleParent = styles;
    } else if (this.props.styleParent === 'looks') {
      styleParent = looks;
    }    
    return (
      <a className={[styleParent.Email, styleParent[this.props.where]].join(' ')} href="mailto:groomsbyblaze@gmail.com">groomsbyblaze@gmail.com</a>
    )
  }
}
export default Email;